// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper__3kO4c {\n  height: 100vh;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  overflow: hidden;\n  font-size: 50px;\n  margin: auto;\n}", "",{"version":3,"sources":["webpack://src/algorithm/pages/error-page/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AACJ","sourcesContent":[".wrapper {\n    height: 100vh;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    overflow: hidden;\n    font-size: 50px;\n    margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper__3kO4c"
};
export default ___CSS_LOADER_EXPORT___;
