import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ErrorCatcher from './commonSources/ErrorCatcher';
import './algorithm/static/ruffle.css';
import Offline from 'react-offline';
import React, { Suspense, useEffect, useState } from 'react';
import Preloader from './commonSources/preloader/Preloader';
import { ErrorProxy } from './utils/throwGlobalAsyncError';
import { ErrorPage } from './algorithm/pages/error-page/Error';

const SingleLazy = React.lazy(() => import('./algorithm/pages/SinglePage'));
const SingleWrapper = () => {
    return (
        <Suspense fallback={<Preloader />}>
            <SingleLazy />
        </Suspense>
    );
};
const AlgorithmLazy = React.lazy(() => import('./algorithm/pages/Algorithm'));
const AlgorithmWrapper = () => {
    return (
        <Suspense fallback={<Preloader />}>
            <AlgorithmLazy />
        </Suspense>
    );
};

const AppContextFull = () => {
    function goUp() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    (function () {
        let oldHref = window.location.href;

        setInterval(function () {
            if (oldHref !== window.location.href) {
                goUp();
                oldHref = window.location.href;
            }
        }, 100);
    })();
    const [offline, setOffline] = useState(false);

    useEffect(() => {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                setNeedRemount(true);
            }
        });
    }, []);

    useEffect(() => {
        if (offline) {
            setTimeout(() => {
                window.location.href = '';
            }, 1000);
        }
    }, [offline]);
    const [needRemount, setNeedRemount] = useState(false);

    useEffect(() => {
        setNeedRemount(false);
    }, [needRemount]);

    if (needRemount) {
        return <Preloader />;
    }
    return (
        <>
            <Offline
                onChange={({ isOffline, isOnline }) => {
                    setOffline(isOffline);
                }}
            />
            <div>
                <ErrorCatcher>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/page" component={SingleWrapper} />
                            <Route exact path="/error" component={ErrorPage} />
                            <Route path="*" component={AlgorithmWrapper} />
                        </Switch>
                    </BrowserRouter>
                    <ErrorProxy />
                </ErrorCatcher>
            </div>
        </>
    );
};
export default AppContextFull;
