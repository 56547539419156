import { useEffect, useState } from 'react';

export let setGlobalError = () => {};
export const ErrorProxy = () => {
    const [error, setError] = useState(<div />);
    useEffect(() => {
        setGlobalError = setError;
    }, []);
    return error;
};
