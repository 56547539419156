import AppContextFull from './AppContextFull';
import { combineComponents } from './algorithm/utils/component';
import { isFirefox, isIE } from 'react-device-detect';
import './static/css/style.min.css';

const providers = [];

const ContextProvider = combineComponents(...providers);

function App() {
    if (isIE) return <div>IE is not supported</div>;
    if (isFirefox) return <div>Firefox is not supported</div>;
    return (
        <ContextProvider>
            <AppContextFull />
        </ContextProvider>
    );
}

export default App;
