import './preloader.scss';
import loader from '../../static/img/loading.gif';

const Preloader = () => {
    return (
        <div className="preloader_area_opacite_wrapper">
            <div className="preloader_area">
                <div className="loading_animation">
                    <img src={loader} />
                </div>
            </div>
        </div>
    );
};
export default Preloader;
